/* eslint-disable */
import { inject, Injectable } from '@angular/core';
import { CoreService } from './core.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  coreService = inject(CoreService);

  getItem(key: string) {
    if (this.coreService.isBrowser()) {
      const value: string = localStorage.getItem(key) || '';
      let json: any;
      try {
        json = JSON.parse(value);
      } catch (e) {
        json = undefined;
      }

      return json || value;
    }
  }

  setItem(key: string, value: any): void {
    if (this.coreService.isBrowser()) {
      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }

      localStorage.setItem(key, value);
    }
  }

  removeItem(key: string) {
    if (this.coreService.isBrowser()) {
      localStorage.removeItem(key);
    }
  }

  clear() {
    if (this.coreService.isBrowser()) {
      localStorage.clear();
    }
  }
}
