import { TranslatableModel } from '../abstract/translateble-model.abstract';
import { MetaModel } from './meta.models';
import { SupporterModel } from './supporter.model';

export class PageModel extends TranslatableModel {
  id: number;
  title: string;
  gallery?: { image: string; alt: string }[];
  slug: string;
  data: any;
  meta: MetaModel;
  partners: { logo: string; link: string; positionTypeId: number; alt?: string }[];
  leftPartners: { logo: string; link: string; alt?: string }[];
  rightPartners: { logo: string; link: string; alt?: string }[];

  override onInit() {
    super.onInit();

    if (this.partners?.length) {
      this.leftPartners = this.partners.filter(el => el.positionTypeId === 1);
      this.rightPartners = this.partners.filter(el => el.positionTypeId === 2);
    }
  }
}
