import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { PageModel } from '../models/page.models';
import { ApiService } from '../abstract/api-service.abstract';
import { LangService } from './lang.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageService extends ApiService {
  pages: any = {};
  private langService = inject(LangService);

  getPage(slug: string, additions: string[] = []): Observable<PageModel> {
    if (this.pages[slug] && additions.length === 0) {
      return of(this.pages[slug]);
    }

    return this.http
      .get(
        `${this.apiFrontUrl}/page/${slug}${
          additions.length ? `?additions=${additions.join(',')}` : ''
        }${additions.length ? '&' : '?'}date=${this.cacheUid()}`
      )
      .pipe(
        map(response => {
          const page = PageModel.fromJson(response, this.langService);
          for (const addition of additions) {
            this.pages[addition] = PageModel.fromJson(page[addition], this.langService);
            delete page[addition];
          }
          this.pages[slug] = page;
          return page;
        })
      );
  }
}
