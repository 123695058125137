import { environment } from '../../../environments/environment';
import { LangService } from '../services/lang.service';

export interface ITranslation {
  locale: string;
  data: any;
  meta: any;
  title?: string;
  placeholder?: string;
  description?: string;
  shortDescription?: string;
  searchPlaceholder?: string;
}

export class TranslatableModel {
  translations: ITranslation[];
  translation: ITranslation | any;
  defaultLang: string = environment.defaultLang;
  locale: string = this.defaultLang;

  constructor(item: object, langService: LangService) {
    if (item) {
      Object.assign(this, item);
      this.onInit();
    }

    if (langService) {
      this.setLang(langService.lang());
      langService.langChanged.subscribe(lang => {
        this.setLang(lang);
      });
    }
  }

  onInit() {}

  static fromJson(item: object, langService: LangService): any {
    const model: object = new this(item, langService);

    return model;
  }

  static fromJsonArray(data: object[], langService: LangService): any[] {
    const models: object[] = [];

    if (data?.length) {
      data.forEach(item => {
        models.push(this.fromJson(item, langService));
      });
    }

    return models;
  }

  setLang(lang: string) {
    if (this.locale === lang && this.translation) {
      return;
    }

    if (this.translations) {
      let translation = this.translations.find(itm => itm.locale === lang);

      if (!translation) {
        translation = this.translations.find(itm => itm.locale === this.defaultLang);
      }

      // Not all translations have the data property!
      // if (!translation?.data) {
      //   translation = this.translations[0];
      // }

      this.translation = translation as ITranslation;
      if (this.translation?.data) {
        this.translation.data = this.translation.data
          ? typeof this.translation.data == 'object'
            ? this.translation.data
            : JSON.parse(this.translation.data)
          : {};
      }

      this.locale = lang;
    }
  }
}
