export class Model {
  constructor(item: object) {
    if (item) {
      Object.assign(this, item);
      this.onInit();
    }
  }

  static fromJson(item: object): any {
    return new this(item);
  }

  static fromJsonArray(data: object[]): any[] {
    const models: object[] = [];

    if (data?.length) {
      data.forEach(item => {
        models.push(this.fromJson(item));
      });
    }

    return models;
  }

  onInit() {}
}
