import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CoreService } from "../services/core.service";
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export abstract class ApiService {
  protected apiFrontUrl = '';
  protected apiMeUrl = '';
  protected apiAuthUrl = '';
  protected http = inject(HttpClient);
  protected coreService = inject(CoreService);


  constructor() {
    if (this.coreService.isBrowser()) {
      this.apiFrontUrl = environment.api.url + '/front';
      this.apiMeUrl = environment.api.url + '/me';
      this.apiAuthUrl = environment.api.url + '/auth';
    } else {
      this.apiFrontUrl = environment.api.ssrUrl + '/front';
      this.apiMeUrl = environment.api.ssrUrl + '/me';
      this.apiAuthUrl = environment.api.ssrUrl + '/auth';
    }
  }

  cacheUid() {
    return Date.now();
    // return moment().utc().startOf('hour').unix();
  }
}
