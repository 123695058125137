import { EventEmitter, inject, Injectable, signal } from '@angular/core';
import { LanguageModel } from '../models/language.model';
import { LocalStorageService } from './local-storage.service';
import { ApiService } from '../abstract/api-service.abstract';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LangService extends ApiService {
  langChanged = new EventEmitter();
  langDefault: LanguageModel = {
    id: 0,
    code: environment?.defaultLang && 'ua',
    title: 'Укр',
  } as LanguageModel;
  private localStorageService = inject(LocalStorageService);
  lang = signal<string>(this.localStorageService.getItem('lang'));

  constructor() {
    super();
    if (!this.lang()) {
      const lang = environment.defaultLang;
      this.localStorageService.setItem('lang', lang);
      this.lang.set(lang);
    }
  }

  list(): Observable<LanguageModel[]> {
    return this.http
      .get<{ languages: LanguageModel[] }>(`${this.apiFrontUrl}/language?date=${this.cacheUid()}`)
      .pipe(
        map((data: { languages: LanguageModel[] }) => {
          return LanguageModel.fromJsonArray(data.languages as LanguageModel[]);
        })
      );
  }

  setLang(lang: string) {
    if (!lang) return;
    this.lang.set(lang);
    this.langChanged.emit(this.lang());
    this.localStorageService.setItem('lang', this.lang());
  }
}
