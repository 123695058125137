// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ExportImport } from '../app/core/enums/export-import.enum';
import { WebsiteEnum } from '../app/core/enums/website.enum';

export const environment = {
  production: false,
  api: {
    url: 'https://diia-lms-backend.demo.ukrohost.com/api',
    ssrUrl: 'https://diia-lms-backend.demo.ukrohost.com/api',
  },
  file: {
    url: 'https://diia-lms-backend.demo.ukrohost.com',
  },
  int: {
    url: 'https://diia-lms-international.demo.ukrohost.com',
  },
  defaultLang: 'ua',
  emulated: {
    active: false,
    email: null,
  },
  website: WebsiteEnum.Main,
  direction: ExportImport.Export,
  googleClientId: '724803653369-mq3qeicjaik9a5kh7o31hc1am92h1dd0.apps.googleusercontent.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
